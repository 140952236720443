import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { _3dViewer, InfoPage } from "@evercam/shared/types"
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from "#app"

export default defineNuxtRouteMiddleware(async ({ params, path }) => {
  const infoPages = [
    {
      name: InfoPage.BimCompare,
      hasFeaturePermission: () =>
        useNuxtApp().nuxt2Context.$permissions.user.can.access.bim() &&
        useNuxtApp().nuxt2Context.$permissions.camera.has.bim() &&
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useCameraStore().cameraRoute}/bim-compare`,
    },
    {
      name: InfoPage.ThreeSixtyView,
      hasFeaturePermission: () =>
        useNuxtApp().nuxt2Context.$permissions.project.has._360View() &&
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/360`,
    },
    {
      name: InfoPage.GateReport,
      hasFeaturePermission: () =>
        useNuxtApp().nuxt2Context.$permissions.user.can.access.gateReport() &&
        useNuxtApp().nuxt2Context.$permissions.project.has.gateReport() &&
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/gate-report`,
    },
    {
      name: InfoPage.BIMView,
      hasFeaturePermission: () =>
        useNuxtApp().nuxt2Context.$permissions.project.has.BIMView() &&
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/3d?viewer=${
        _3dViewer.Itwin
      }`,
    },
    {
      name: InfoPage.DroneView,
      hasFeaturePermission: () =>
        useNuxtApp().nuxt2Context.$permissions.project.has.droneView() &&
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/3d?viewer=${
        _3dViewer.Cesium
      }`,
    },
    {
      name: InfoPage.WeatherReport,
      hasFeaturePermission: () =>
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/`,
    },
    {
      name: InfoPage.Timeline,
      hasFeaturePermission: () =>
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/`,
    },
    {
      name: InfoPage.Compare,
      hasFeaturePermission: () =>
        !useNuxtApp().nuxt2Context.$permissions.project.has.go(),
      redirectUrl: `${useProjectStore().projectRoute}/`,
    },
  ]

  const matchedCurrentInfoPage = infoPages.find(
    (page) => page.name === params.page
  )

  if (!matchedCurrentInfoPage) {
    return navigateTo(`${path}/404`)
  }

  if (
    matchedCurrentInfoPage?.hasFeaturePermission() &&
    useAccountStore().token
  ) {
    return navigateTo(matchedCurrentInfoPage.redirectUrl)
  }
})
