
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import TheRightSidebar from "@/components/TheRightSidebar"
export default {
  components: {
    TheRightSidebar,
  },
  computed: {
    ...mapStores(useLayoutStore),
    contentStyle() {
      if (this.layoutStore.mainContentRect?.height) {
        return {
          height: `${this.layoutStore.mainContentRect.height}px`,
        }
      }

      return {}
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateLayoutStore()
        this.$setTimeout(
          () => this.$refs.mainContent?.scrollTo({ top: 0 }),
          200
        )
      },
    },
  },
  mounted() {
    this.updateLayoutStore()
  },
  methods: {
    updateLayoutStore() {
      this.$nextTick(() => {
        const contentElement = this.$refs.mainContent
        if (!contentElement) {
          return
        }
        const contentRect = contentElement.getBoundingClientRect()
        const x = contentRect.x
        const y = contentRect.y
        const width = window.innerWidth - contentRect.left
        const height = window.innerHeight - contentRect.top

        this.layoutStore.mainContentRect = new DOMRect(x, y, width, height)

        if (this.layoutStore.isCleanMode) {
          this.layoutStore.enableCleanMode()
        }
      })
    },
  },
}
