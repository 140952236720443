import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { camelizeKeys } from "humps"
import { defineNuxtRouteMiddleware, navigateTo } from "#app"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"

export default defineNuxtRouteMiddleware((to) => {
  const cameraStore = useCameraStore()
  const accountStore = useAccountStore()
  const projectStore = useProjectStore()
  const { cameraExid, projectExid } = camelizeKeys(to.params)

  const cameraRoute = `/v2/projects/${projectExid}/${cameraExid}`

  const areCameraAndProjectFetched =
    cameraStore.selectedCamera && projectStore.selectedProject

  const areSameProject =
    cameraStore.selectedCamera?.project?.id === projectStore.selectedProjectExid
  if (
    (!areSameProject || !areCameraAndProjectFetched) &&
    accountStore.token &&
    !cameraStore.isCameraPublic
  ) {
    return navigateTo(`${cameraRoute}/404`)
  }

  const targetAppPath = projectStore.getTargetAppPath()
  const shouldDefaultToProjectApp =
    projectStore.selectedProject?.featureFlags?.includes(
      ProjectFeatureFlag.DefaultToProjectApp
    )
  if (shouldDefaultToProjectApp && targetAppPath) {
    return navigateTo(targetAppPath)
  }
})
